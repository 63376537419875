<template>
	<div class="content-box">
		<div class="container">
			<div class="search">
				<div class="a-inline-block">
					<label for="">关联客户</label><a-input v-model="tmjcpb_corp__name" size="large" placeholder="请输入关联客户" />
				</div>
				<div class="a-inline-block">
					<label for="">联系人</label><a-input v-model="tmjcpb_corps__zd1719102503" size="large"
						placeholder="请输入联系人" />
				</div>
				<div class="a-inline-block">
					<label for="">联系电话</label><a-input v-model="tmjcpb_corps__zd1719102509" size="large"
						placeholder="请输入联系电话" />
				</div>
				<div class="a-inline-block">
					<label for="">添加人</label><a-input v-model="creater_uid" size="large" placeholder="请输入联系电话" />
				</div>
				<!-- <div class="a-inline-block">
					<label for="">订单状态</label>
					<a-select size="large" v-model="status">
						<a-select-option :value="null">全部</a-select-option>
						<a-select-option v-for="(item, index) in orderStatus" :key="item"
							:value="index">{{ item }}</a-select-option>
					</a-select>
				</div> -->
				<div class="a-inline-block2">
					<label class="info" for="">添加时间</label>
					<a-range-picker v-model="date" size="large" format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" />
				</div>
				<div class="a-inline-block">
					<a-button size="large" type="primary" @click="onSearch">搜索</a-button>
					<a-button size="large" @click="reset">重置</a-button>
				</div>

				<!-- <div style="text-align:right;">
					<a-button style="width:auto;" size="large" type="primary" @click="delAll">批量删除</a-button>
				</div> -->
			</div>

			<div class="screen tMenu">
				<div class="a-inline-block" @click="excelout">
					<img src="@/assets/imgs/tMenu4.png" alt=""><label for="">导出</label>
				</div>
			</div>
			<a-table :rowKey='record => record.id' :columns="columns" :data-source="data" class="table" id="myTable"
				:pagination="pagination" :scroll="{ x: true }">
				<span slot="num" slot-scope="text, record, index">
					{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}
				</span>
				<span slot="type" slot-scope="text, record">
					{{ record.type.toString().indexOf(1)>-1 && record.type.toString().indexOf(2)>-1? '团购,外卖' :record.type.toString().indexOf(1)>-1?'团购': record.type.toString().indexOf(2)>-1?'外卖':'' }}
				</span>
				<template slot="Action" slot-scope="text,record">
					<a class="action" href="javascript:;" @click="showDetail(record.id)">查看</a>
					<a class="action" href="javascript:;" @click="editOrder(record.id)"
						v-if="record.status != '已完成' && record.status != '关闭'">编辑</a>

					<a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
						<a class="action del" href="javascript:;">删除</a>
					</a-popconfirm>
				</template>
			</a-table>
		</div>
	</div>
</template>
<script>
import { requestXml, getDateTime,ajaxUrl } from '../../../assets/js/request';
// var moment = require('moment');
export default {
	data() {
		return {
			// 订单编号
			creater_uid: "",
			// 客户名称
			tmjcpb_corp__name: "",
			// 跟进人
			tmjcpb_corps__zd1719102503: "",
			tmjcpb_corps__zd1719102509: "",
			staffFlag: false,

			// 开始时间
			start: "",
			// 结束时间
			end: "",
			// 签订时间
			date: [],
			// status: null,
			// orderStatus: ["关闭", "新建", "进行中", "已完成"],
			columns: [
				{
					title: '序号', dataIndex: 'num', width: 60, align: 'center',
					scopedSlots: { customRender: 'num' },
				},
				// { title: '进件编号', dataIndex: 'osn', width: 120 },
				{ title: '关联客户', dataIndex: 'name', width: 120 },
				// { title: '关联客户', dataIndex: 'tmjcpb_corp__name', width: 120 },
				{ title: '联系人', dataIndex: 'zd1719102503', width: 120 },
				{ title: '联系电话', dataIndex: 'zd1719102509', width: 120 },
				{ title: '类型', dataIndex: 'type', width: 100, scopedSlots: { customRender: 'type' }, },
				{ title: '添加人', dataIndex: 'creater_uid', width: 80 },
				{ title: '添加时间', dataIndex: 'created', width: 120 },
				{
					title: '操作',
					key: 'operation',
					fixed: 'right',
					width: 250,
					scopedSlots: { customRender: 'Action' },
				}
			],
			data: [],
			selectedRows: [],
			rowSelection: {
				onSelect: (record, selected, selectedRows) => {
					console.log(record, selected, selectedRows);
					this.selectedRows = selectedRows;
				},
				onSelectAll: (selected, selectedRows, changeRows) => {
					console.log(selected, selectedRows, changeRows);
					this.selectedRows = selectedRows;
				},
			},

			// 分页
			pagination: {
				current: 1,
				total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
				showQuickJumper: true,
				// showTotal: total => `Total ${total} items`, // 显示总数
				onChange: (current) => this.changePage(current), //点击当前页码
				onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
					console.log(pageSize);
					this.showSizeChange(current, pageSize)
				},
			},
			selectedRowKeys: []
		};
	},
	mounted: function () {
		this.getOrder();
		// this.getField();

	},
	methods: {
		// 分配完 取消表格选中 清空客户 重新加载列表
		updateStaffFlag(data) {
			console.log(data, "dasds")
			if (data != 1) {
				this.staff_uid = data.userid;
				this.staff_uid2 = data.name;
			}
			this.staffFlag = false;
		},
		// 搜索
		onSearch() {
			this.pagination.current = 1;
			if (this.date.length != 0) {
				this.date[0]._d.setHours(0);
				this.date[0]._d.setMinutes(0);
				this.date[0]._d.setSeconds(0);

				this.date[1]._d.setHours(23);
				this.date[1]._d.setMinutes(59);
				this.date[1]._d.setSeconds(59);
				console.log(this.date)
				this.start = this.date[0]._d.getTime();
				this.end = this.date[1]._d.getTime();
			} else {
				this.start = "";
				this.end = "";
			}
			this.getOrder();
		},
		reset() {
			this.tmjcpb_corp__name = "";
			this.tmjcpb_corps__zd1719102503 = "";
			this.date = [];
			this.start = "";
			this.end = "";
			this.creater_uid = "";
			this.tmjcpb_corps__zd1719102509 = "";
			this.getOrder();
		},
		getField() {
			requestXml("/scrm/Order/getField", "POST", (res) => {
				for (let i = 0; i < res.length; i++) {
					let obj = {};
					obj.title = res[i].text;
					obj.dataIndex = res[i].name;
					obj.width = 100;

					this.columns.splice(this.columns.length - 1, 0, obj);
				}
			})
			// console.log(this.columns,"this.columns")
		},
		getOrder() {
			requestXml("/scrm/corp/purchase", "POST", (res) => {
				for (let i = 0; i < res.list.length; i++) {
					res.list[i].created = getDateTime(res.list[i].created);
				}
				this.pagination.total = Number(res.page.total);
				this.pagination.pageSize = Number(res.page.pagesize);
				this.data = res.list;

			}, {
				"tmjcpb_corp__name": this.tmjcpb_corp__name,
				"tmjcpb_corps__zd1719102503": this.tmjcpb_corps__zd1719102503,
				"start": this.start,
				"end": this.end,
				// "status": this.status,
				"tmjcpb_corps__zd1719102509": this.tmjcpb_corps__zd1719102509,
				"page": { "curpage": this.pagination.current, "pagesize": this.pagination.pageSize }
			})
		},
		// 分页
		changePage(current) {
			//current参数表示是点击当前的页码，
			// console.log(current)
			this.pagination.current = current;
			this.getOrder(); //向后端发送请求
		},
		showSizeChange(current, pageSize) {
			// console.log(current, pageSize)
			this.pagination.current = current;
			this.pagination.pageSize = pageSize;
			this.getOrder(); //向后端发送请求
		},
		// 查看
		showDetail(id) {
			this.$router.push({
				path: "/scrm_pc/client/purchaseDetail",
				query: {
					id: id,
					// staff_uid: this.staff_uid
				}
			});
		},
		// 编辑
		editOrder(id) {
			this.$router.push({
				path: "/scrm_pc/client/addPurchase",
				query: {
					id: id,
				}
			});
		},
		// 删除
		onDelete(id) {
			requestXml("/scrm/corp/delpurchase", "POST", (res) => {
				if (res == "success") {
					this.getOrder();
				}
			}, { "id": id })
		},
		delAll() {
			if (this.selectedRows.length < 1) {
				this.$message.info('请先选择');
				return false;
			} else {
				let idArr = [];
				let sel = this.selectedRows;
				for (let i = 0; i < sel.length; i++) {
					idArr.push(sel[i].id);
				}
				this.onDelete(idArr);
			}
		},
		// 导出
		excelout() {
			console.log(ajaxUrl)
			window.open(ajaxUrl + "/scrm/corp/excelpurchase?tmjcpb_corp__name=" + JSON.stringify(this.tmjcpb_corp__name)+
				'&tmjcpb_corps__zd1719102503=' + JSON.stringify(this.tmjcpb_corps__zd1719102503)+
				'&start=' + JSON.stringify(this.start)+
				'&end=' + JSON.stringify(this.end)+
				'&tmjcpb_corps__zd1719102509=' + JSON.stringify(this.tmjcpb_corps__zd1719102509)
			);
		},
	},

};
</script>
<style scoped>
.order .search .ant-calendar-picker {
	width: 300px !important;
}

.action {
	display: inline-block;
	padding: 5px 10px;
	margin: 0 5px;
	background: #DCEEFF;
	border: 1px solid #36A3FF;
	color: #36A3FF;
	border-radius: 5px;
}

.del {
	background: #FFDCDC;
	border-color: #FF4C4C;
	color: #FF4C4C;
}
</style>
